import React from "react";
import { Message } from "~/types";
import { TelemetryView } from "./Telemetry";
import { UserAttributes } from "./UserAttributes";

interface PanelsProps {
  isUserAttributesOpen: boolean;
  isDebugPanelOpen: boolean;
  setIsDebugPanelOpen: (isOpen: boolean) => void;
  adText: string;
  isGenerating: boolean;
  userMessages: Message[];
}

export const Panels = ({
  isUserAttributesOpen,
  isDebugPanelOpen,
  setIsDebugPanelOpen,
  adText,
  isGenerating,
  userMessages,
}: PanelsProps) => {
  return (
    <>
      {/* User Attributes Panel */}
      <div
        className={`transition-all duration-300 border-l border-gray-200 bg-white overflow-hidden ${
          isUserAttributesOpen ? "w-[40%]" : "w-0"
        }`}
      >
        {isUserAttributesOpen && (
          <div className="h-screen overflow-y-auto p-4">
            <UserAttributes adText={adText} />
          </div>
        )}
      </div>

      {/* Debug Panel */}
      <div
        className={`transition-all duration-300 border-l border-gray-200 bg-white overflow-hidden ${
          isDebugPanelOpen ? "w-[40%]" : "w-0"
        }`}
      >
        {isDebugPanelOpen && (
          <div className="h-screen overflow-y-auto p-4">
            <TelemetryView
              isGenerating={isGenerating}
              answer={userMessages.length > 0 ? userMessages[userMessages.length - 1].content : ""}
              onClose={() => setIsDebugPanelOpen(false)}
            />
          </div>
        )}
      </div>
    </>
  );
};
