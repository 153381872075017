import React, { useEffect, useState } from "react";
import { useChatMode } from "~/providers/ChatModeProvider";
import { Message } from "~/types";
import { useGPT } from "~/hooks/useGPT";
import { ChatHeader } from "./ChatHeader";
import { ChatScrollView } from "./ChatScrollView";
import { Panels } from "./Panel";

export const ChatContent = () => {
  const [isDebugPanelOpen, setIsDebugPanelOpen] = useState(false);
  const [isUserAttributesOpen, setIsUserAttributesOpen] = useState(false);
  const { adType } = useChatMode();
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const userMessages = messages.filter((message) => message.role === "user");

  const [streamingMessage, setStreamingMessage] = useState<string>("");
  const [adText, setAdText] = useState<string>();

  const { mutate, isPending: isGeneratingResponse } = useGPT({
    adType: adType,
    messages,
    setStreamingMessage,
  });
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    ask(message);
  }

  function ask(message: string) {
    setIsGenerating(true);
    prepareForStream();

    // Add the user message to the messages array
    setMessages((prev) => [
      ...prev,
      { id: Math.random().toString(), role: "user", content: message },
    ]);

    mutate(
      { message, history: messages, ad_type: adType },
      {
        onSuccess: async (response: string) => {
          try {
            const promises = [
              window.koah?.process(message, streamingMessage, adType.toLowerCase()),
            ];

            const results = await Promise.all(promises);
            const koahResponse = results[0];
            console.log("KOAH RESPONSE: ", koahResponse);
            if (koahResponse && koahResponse.ad_text) {
              setAdText(koahResponse.ad_text);
            }

            setIsGenerating(false);
          } catch (error) {
            console.error("Error processing message: ", error);
            setIsGenerating(false);
          }
        },
        onSettled: (response: string, error) => {
          console.log("RESPONSE: ", response);

          if (response) {
            setStreamingMessage("");
            // Add the assistant message to the messages array
            setMessages((prev) => [
              ...prev,
              {
                id: Math.random().toString(),
                role: "assistant",
                content: response,
              },
            ]);
          }
        },
      },
    );
  }

  function prepareForStream() {
    setMessage("");
  }

  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === "Escape") {
        setIsDebugPanelOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  return (
    <>
      <ChatHeader
        isUserAttributesOpen={isUserAttributesOpen}
        isDebugPanelOpen={isDebugPanelOpen}
        setIsUserAttributesOpen={setIsUserAttributesOpen}
        setIsDebugPanelOpen={setIsDebugPanelOpen}
      />

      <div className="flex w-full h-screen">
        <div
          className={`flex-1 transition-all duration-300 transform ${
            isDebugPanelOpen ? "" : "translate-x-0"
          }`}
        >
          <ChatScrollView
            messages={messages}
            message={message}
            setMessage={setMessage}
            handleSubmit={handleSubmit}
            isGenerating={isGenerating}
            streamingMessage={streamingMessage}
            isGeneratingResponse={isGeneratingResponse}
          />
        </div>

        <Panels
          isUserAttributesOpen={isUserAttributesOpen}
          isDebugPanelOpen={isDebugPanelOpen}
          setIsDebugPanelOpen={setIsDebugPanelOpen}
          adText={adText}
          isGenerating={isGenerating}
          userMessages={userMessages}
        />
      </div>
    </>
  );
};
