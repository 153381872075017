import React from "react";
import { Bug, User } from "lucide-react";
import ChatAdModeSelector from "./ChatAdModeSelector";

interface ChatHeaderProps {
  isUserAttributesOpen: boolean;
  isDebugPanelOpen: boolean;
  setIsUserAttributesOpen: (isOpen: boolean) => void;
  setIsDebugPanelOpen: (isOpen: boolean) => void;
}

export const ChatHeader = ({
  isUserAttributesOpen,
  isDebugPanelOpen,
  setIsUserAttributesOpen,
  setIsDebugPanelOpen,
}: ChatHeaderProps) => {
  return (
    <div className="absolute top-4 left-0 px-4 flex justify-between w-full z-10">
      <ChatAdModeSelector />
      <div className="flex">
        <button
          className={`rounded-lg p-2 hover:bg-gray-100 w-10 h-10 flex items-center justify-center transition-all duration-300 text-black ${
            isUserAttributesOpen ? "bg-gray-100 hover:text-black" : "bg-white hover:text-slate-500"
          }`}
          onClick={() => {
            setIsUserAttributesOpen(!isUserAttributesOpen);
            if (isDebugPanelOpen) setIsDebugPanelOpen(false);
          }}
        >
          <User size={20} />
        </button>
        <button
          className={`rounded-lg p-2 hover:bg-gray-100 w-10 h-10 flex items-center justify-center transition-all duration-300 text-black ${
            isDebugPanelOpen ? "bg-gray-100 hover:text-black" : "bg-white hover:text-slate-500"
          }`}
          onClick={() => {
            setIsDebugPanelOpen(!isDebugPanelOpen);
            if (isUserAttributesOpen) setIsUserAttributesOpen(false);
          }}
        >
          <Bug size={20} />
        </button>
      </div>
    </div>
  );
};
