import React from "react";
import { BubbleType } from "./RootBubbleView";
import { ChatEmptyView } from "./ChatEmptyView";
import AssistantBubble from "./AssistantBubble";
import { UserBubble } from "./UserBubble";
import { Message } from "~/types";
import InputBox from "./InputBox";

interface ChatScrollViewProps {
  messages: Message[];
  message: string;
  setMessage: (message: string) => void;
  streamingMessage: string;
  isGeneratingResponse: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isGenerating: boolean;
}

export const ChatScrollView = ({
  messages,
  message,
  setMessage,
  streamingMessage,
  isGeneratingResponse,
  handleSubmit,
  isGenerating,
}: ChatScrollViewProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-full relative">
      {messages.length === 0 && <ChatEmptyView />}
      <div className="flex flex-col w-full h-full gap-4 overflow-y-scroll pb-[300px]">
        <div className="flex flex-col gap-4 min-w-[700px] max-w-[700px] mx-auto">
          {messages.map((message, idx) => {
            const bubbleType =
              idx === 0
                ? BubbleType.FIRST
                : idx === messages.length - 1 && !streamingMessage
                ? BubbleType.LAST
                : null;

            if (message.role === "assistant") {
              return (
                <AssistantBubble
                  key={message.id}
                  type={bubbleType}
                  text={message.content}
                  isGeneratingResponse={false}
                />
              );
            } else {
              return <UserBubble key={message.id} type={bubbleType} message={message} />;
            }
          })}
          {streamingMessage && (
            <AssistantBubble
              key={messages.length + 1}
              text={streamingMessage}
              type={BubbleType.LAST}
              isGeneratingResponse={isGeneratingResponse}
            />
          )}
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0 bg-white">
        <InputBox
          message={message}
          setMessage={setMessage}
          onSubmit={handleSubmit}
          isGenerating={isGenerating}
        />
      </div>
    </div>
  );
};
