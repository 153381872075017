import React, { createContext, useState, useContext } from "react";

interface ChatModeContextType {
  adType: ChatOption;
  setAdType: (adType: ChatOption) => void;
  isFollowUpsEnabled: boolean;
  setIsFollowUpsEnabled: (isEnabled: boolean) => void;
}

const ChatModeContext = createContext<ChatModeContextType | null>(null);

export enum ChatOption {
  NO_ADS = "No Ads",
  SUFFIX = "Suffix",
  INLINE = "Inline",
  PREFIX = "Prefix",
}

export const ChatModeProvider = ({ children }: { children?: React.ReactNode }) => {
  const [adType, setAdType] = useState<ChatOption>(ChatOption.SUFFIX);
  const [isFollowUpsEnabled, setIsFollowUpsEnabled] = useState<boolean>(false);

  return (
    <ChatModeContext.Provider
      value={{
        adType,
        setAdType,
        isFollowUpsEnabled,
        setIsFollowUpsEnabled,
      }}
    >
      {children}
    </ChatModeContext.Provider>
  );
};

export const useChatMode = () => {
  const context = useContext(ChatModeContext);

  if (!context) {
    throw new Error("useChatMode must be used within a ChatModeProvider");
  }
  return context;
};
